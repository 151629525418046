<template>
  <div>
    <van-nav-bar
        title="新增账单"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="toHome"/>
      </template>
    </van-nav-bar>
    <van-form validate-first @failed="onFailed">
      <!-- 通过 pattern 进行正则校验 -->
      <van-field
          v-model="orderUser.username"
          label="商户"
          name="pattern"
          placeholder="请选择商户"
          readonly
      >
        <template #button>
          <van-button size="small" type="primary" @click="showPopup">选择商户</van-button>
        </template>
      </van-field>
      <!-- 通过 validator 进行函数校验 -->
      <van-field
          v-model="orderUser.phone"
          label="手机号码"
          name="validator"
          placeholder="请选择商户"
          readonly
      />

      <van-field
          v-model="deliveryAddress"
          is-link
          readonly
          label="送货地址"
          placeholder="请选择所在地区"
          @click="selectAddressPopupShow = true"
      />
      <van-popup v-model="selectAddressPopupShow" round position="bottom">
        <van-cascader
            v-model="cascaderValue"
            title="请选择所在地区"
            :options="options"
            :field-names="fieldNames"
            @close="selectAddressPopupShow = false"
            @finish="onFinish"
            @change="onAreaChange"
        />
      </van-popup>

      <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="orderInfo.orderDatetimeStr"
          label="时间选择"
          placeholder="点击选择时间"
          @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-datetime-picker
            v-model="orderInfo.orderDatetime"
            type="datetime"
            title="选择完整时间"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="onConfirm"
            @cancel="showPicker = false"
        />
      </van-popup>
      <van-divider
          :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
      >
        商品信息
      </van-divider>

      <div ref="container" style="padding-bottom: 35px">
        <van-sticky :container="container" :offset-top="50">
          <van-button type="primary" style="margin-left: 15px" @click="onAdd()">添加用户</van-button>
          <van-button type="primary" style="margin-left: 15px; margin-bottom: 10px;" @click="showProductPopup()">选择商品
          </van-button>
        </van-sticky>

        <div v-for="item in selectedProducts" :key="item.id" :name="item.id">

          <div class="van-coupon">
            <div>
              <van-row>
                <van-col span="16">
                  <div style="height: 20px; padding-top: 5px;">
                    <span style="font-size: 12px; color: #323233; margin-left: 10px">{{ item.storeName }}</span>
                  </div>
                </van-col>
                <van-col span="8">
                  <div style="height: 20px; padding-top: 5px; text-align: right;">
                    <van-icon name="edit" color="#1989fa" size="25px" style="margin-right: 5px"
                              @click="editProduct(item)"/>
                  </div>
                </van-col>
              </van-row>
            </div>
            <div class="van-coupon__content" style="padding: 0" @click="getAccountOrderInfo(item.id)">
              <div class="van-coupon__head" style="max-width: 80px">
                <h3 class="van-coupon__amount" style="font-size: 16px">{{ item.totalPrice }}<span>元</span></h3>
                <p class="van-coupon__condition">数量：{{ item.totalNum }}</p>
              </div>
              <div class="van-coupon__body">
                <p class="van-coupon__name">{{ item.storeInfo }}</p>
                <p class="van-coupon__valid">{{ item.sku }}</p>
              </div>
            </div>
          </div>
        </div>


        <van-popup v-model="showEditProduct" style="width: 90%; max-height: 90%; padding-bottom: 10px">
          <van-card
              :desc="selectedProduct.storeInfo"
              :title="selectedProduct.storeName"
              :thumb="selectedProduct.image"
              :origin-price="selectedProduct.price"
          />
          <div v-for="item in selectedProduct.storeProductAttrValues" :key="item.unique" :name="item.unique">
            <div tabindex="0"
                 class="van-cell van-cell--center van-cell--borderless van-contact-card van-contact-card--edit">
              <div class="van-cell__value van-cell__value--alone van-contact-card__value">
                <span>{{ item.sku }}</span>
                <van-field v-model="item.price" left-icon="refund-o" @focus="clearPrice(item)"
                           size="small" rows="1" type="number" style="margin-bottom: 3px;"
                           @input="onChangeAmount"/>
                <van-stepper v-model.number="item.number"
                             step="1"
                             integer
                             input-width="40px"
                             button-size="40px"
                             @change="onChangeAmount"/>
              </div>
              <van-button icon="delete-o" size="small" type="info" @click="onDeleteProductAttr(item.productAttrUnique)">删除</van-button>
<!--              <van-icon  name="delete-o" size="20px" @click="onDeleteProductAttr(item.productAttrUnique)"/>-->
            </div>
          </div>

          <van-button type="primary" size="small" block style="width: 90%; margin: 5px auto 0;"
                      @click="submitEditProduct">
            确定
          </van-button>
        </van-popup>
      </div>

      <div style="margin: 16px;">
        <van-submit-bar :price="amount" button-text="提交订单" @submit="onShowPaymentBeforeSubmit"/>
      </div>
    </van-form>
    <van-popup v-model="isPaymentShow" position="bottom" :style="{ height: '40%' }">
      <van-form @submit="onSubmitForOrder">
        <van-cell-group inset>
          <van-field name="radio" label="支付">
            <template #input>
              <van-radio-group v-model="paymentStatusChecked" direction="horizontal" @change="onPaymentStatusChange">
                <van-radio name="0">欠账</van-radio>
                <van-radio name="1">付清</van-radio>
                <van-radio name="2">结账</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field name="radio" label="支付方式">
            <template #input>
              <van-radio-group v-model="paymentTypeChecked" direction="horizontal">
                <van-radio name="微信" style="margin-top: 2px;">微信</van-radio>
                <van-radio name="现金" style="margin-top: 2px;">现金</van-radio>
                <van-radio name="支付宝" style="margin-top: 2px;">支付宝</van-radio>
                <van-radio name="银行卡" style="margin-top: 2px;">银行卡</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field v-model="payPrice"
                     size="small" label="支付金额" rows="1" type="number" style="margin-bottom: 3px;"/>
          <van-field
              v-model="mark"
              rows="2"
              autosize
              label="留言"
              type="textarea"
              maxlength="50"
              placeholder="请输入留言"
              show-word-limit
          />
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  CellGroup,
  Toast,
  Form,
  Button,
  Field,
  Popup,
  Stepper,
  SubmitBar,
  Divider,
  DatetimePicker,
  Sticky,
  Notify,
  Cascader,
  Dialog,
  CouponCell, NavBar, Radio, RadioGroup
} from 'vant';

import * as adminService from "@/api/admin/login";
import * as getDataService from "@/api/serverData/getServerData";
import * as adminOrderService from "@/api/admin/order";
import {mapState} from 'vuex'
import $store from "@/store";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
    [Form.name]: Form,
    [Button.name]: Button,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Stepper.name]: Stepper,
    [SubmitBar.name]: SubmitBar,
    [Divider.name]: Divider,
    [DatetimePicker.name]: DatetimePicker,
    [Sticky.name]: Sticky,
    [Notify.name]: Notify,
    [Cascader.name]: Cascader,
    [CouponCell.name]: CouponCell,
    [NavBar.name]: NavBar,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
  },
  data() {
    return {
      showEditProduct: false,
      selectedProduct: {},
      selectAddressPopupShow: false,
      cascaderValue: '',
      options: [
        {
          text: '浙江省',
          value: '330000',
          children: [{text: '杭州市', value: '330100'}],
        },
        {
          text: '江苏省',
          value: '320000',
          children: [{text: '南京市', value: '320100'}],
        },
      ],
      fieldNames: {
        text: 'n',
        value: 'v',
        children: 'c',
      },
      container: null,
      sidebarContainer: null,
      orderInfo: {
        orderDatetime: new Date()
      },
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      showPicker: false,
      isPaymentShow: false,
      payPrice: 0,
      mark: '',
      paymentStatusChecked: '0',
      paymentTypeChecked: '微信',
      verifySuccess: true
    };
  },
  computed: {
    ...mapState({
      product: state => state.order.product,
      // selectedProducts: state => state.order.products,
      orderUser: state => state.order.orderUser,
    }),
    // 箭头函数可使代码更简练

    // 传字符串参数 'count' 等同于 `state => state.count`
    // countAlias: 'count',
    selectedProducts: {
      get() {
        return this.$store.state.order.mergeProducts
      },
      set(v) {
        this.$store.state.order.mergeProducts = v
      }
    },
    amount: {
      get() {
        return this.$store.state.order.amount;
      },
      set(v) {
        this.$store.state.order.amount = v
      }
    },
    deliveryAddress: {
      get() {
        return this.$store.state.order.orderUser.deliveryAddress;
      },
      set(v) {
        let user = this.$store.state.order.orderUser
        user.deliveryAddress = v
        $store.commit('SET_USER', user)
      }
    }
    // 为了能够使用 `this` 获取局部状态，必须使用常规函数
    // countPlusLocalState (state) {
    //   return state.count + this.localCount
    // }
  },
  watch: {
    selectedProducts: {
      handler(newVal) {
        newVal.forEach(item => {
          item.storeName = item.keyword
          item.storeProductAttrValues.forEach(skuItem => {
            skuItem.storeName = item.keyword
          })
        })
        this.onChangeAmount()
      },
      immediate: true,
    }
  },
  mounted() {
    this.container = this.$refs.container;
    this.sidebarContainer = this.$refs.sidebarContainer;
  },
  created() {
    const currentTime = new Date();
    this.orderInfo.orderDatetimeStr = `${currentTime.getFullYear()}/${currentTime.getMonth() + 1}/${currentTime.getDate()} ${currentTime.getHours()}:${currentTime.getMinutes()}`;
    getDataService.getCity().then(res => {
      if (res.status === 200) {
        this.options = res.data
      }
    });
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    toHome() {
      this.$router.push({path: '/index'})
    },
    editProduct(product) {
      this.selectedProduct = product
      this.showEditProduct = true
    },
    submitEditProduct() {
      let amount = 0;
      let number = 0;
      let skuArray = [];
      console.log(this.selectedProduct.storeProductAttrValues)

      if (this.selectedProduct.storeProductAttrValues.length === 0) {
        this.selectedProduct = {}
        this.showEditProduct = false
        this.onChangeAmount()
        return
      }
      this.selectedProduct.storeProductAttrValues.forEach((item, index) => {
        amount += item.price * item.number
        number += item.number;
        item.productAttrUnique = item.unique
        skuArray.push(item.sku);
      })
      this.selectedProduct.sku = skuArray.join(',')
      this.selectedProduct.totalNumber = number
      this.selectedProduct.totalPrice = amount

      this.onChangeAmount()

      this.showEditProduct = false
      this.selectedProduct = {}
    },
    onDeleteProductAttr(productAttrUnique) {
      for (let j = 0; j < this.selectedProduct.storeProductAttrValues.length; j++) {
        if (this.selectedProduct.storeProductAttrValues[j].productAttrUnique === productAttrUnique) {
          this.selectedProduct.storeProductAttrValues.splice(j, 1);
        }
      }
      for (let j = 0; j < this.selectedProducts.length; j++) {
        console.log(this.selectedProducts[j].storeProductAttrValues.length)
        if (this.selectedProducts[j].storeProductAttrValues === undefined || this.selectedProducts[j].storeProductAttrValues.length === 0) {
          this.selectedProducts.splice(j, 1);
        }
      }
      this.onChangeAmount()
    },
    onChangeAmount() {
      let amount = 0;
      let number = 0;
      this.selectedProducts.forEach(item => {
        let subAmount = 0;
        let subNumber = 0;
        let skuArray = []
        item.storeProductAttrValues.forEach(subItem => {
          subAmount += subItem.price * subItem.number;
          subNumber += subItem.number;
          skuArray.push(subItem.sku)
        })
        item.sku = skuArray.join(',')
        item.totalNum = subNumber
        item.totalPrice = subAmount
        amount += subAmount;
        number += subNumber;
      })
      this.amount = amount * 100;
      this.orderInfo.totalNum = number;
    },

    toOrderInfo(orderId) {
      this.$router.push({path: '/order/info/' + orderId})
    },
    deleteOrder(orderId) {
      Dialog.confirm({
        title: '确认删除此订单吗？',
        message: '删除此订，无法找回！',
      })
          .then(() => {
            adminOrderService.deleteAccountOrder(orderId).then(res => {
              if (res.status === 200) {
                Notify({type: 'success', message: '删除成功'});
                this.onRefresh()
              } else {
                Notify({type: 'danger', message: '删除失败'});
              }
            })
            // on confirm
          })
          .catch(() => {
            // on cancel
          });

    },
    toOrderIndo() {
    },
    getAccountOrderInfo(orderId) {
      adminOrderService.getAccountOrderInfo(orderId).then(res => {
        if (res.status === 200) {
          this.orderInfos = res.data
        }
      });
      this.orderInfoPopupShow = true;
    },
    clearPrice(item) {
      item.price = ''
    },
    onShowPaymentBeforeSubmit() {
      this.isPaymentShow = true
      this.onChangeAmount()
    },
    onSubmitForOrder() {
      this.onChangeAmount()
      this.verifyPayment()
      if (!this.verifySuccess) {
        return
      }
      let accountOrderInfos = []
      this.selectedProducts.forEach(item => {
        item.storeProductAttrValues.forEach(tt => {
          accountOrderInfos.push(tt)
        })
      })
      this.orderInfo.userId = this.orderUser.id
      this.orderInfo.accountOrderInfos = accountOrderInfos;
      this.orderInfo.totalPrice = this.amount / 100;
      this.orderInfo.username = this.orderUser.username
      this.orderInfo.phone = this.orderUser.phone
      this.orderInfo.address = this.deliveryAddress
      this.orderInfo.deliveryAddress = this.deliveryAddress
      this.orderInfo.payPrice = this.payPrice
      if (this.payPrice > 0) {
        this.orderInfo.payType = this.paymentTypeChecked
      }
      this.orderInfo.statusOfFront = this.paymentStatusChecked
      this.orderInfo.mark = this.mark
      console.log(this.orderInfo)

      if (this.orderInfo.username === undefined) {
        Toast("请选择商户")
        return
      }
      if (this.orderInfo.accountOrderInfos.length < 1) {
        Toast("请选择商户购买的商品")
        return
      }

      Dialog.confirm({
        title: '确认修改此订单吗？',
        message: '请仔细检查～',
      })
        .then(() => {
          adminService.addAccount(this.orderInfo).then(res => {
            this.orderInfo.accountOrderInfos = [];
            this.selectedProducts = []
            this.orderInfo.totalNum = 0;
            this.orderInfo.totalPrice = 0;
            this.amount = 0;
            this.resetPayment()
            Notify({type: 'success', message: '新增成功'});
            this.changeCurrentTimeForPicker()
          }).thenReject(res => {
            console.log(res)
            Notify({type: 'danger', message: res});
            this.changeCurrentTimeForPicker()
          }).fail(res => {
            console.log('fail')
          }).catch(res => {
            console.log('catch')
          })
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    resetPayment() {
      this.isPaymentShow = false
      this.payPrice = 0
      this.paymentStatusChecked = '0'
      this.paymentTypeChecked = '微信'
      this.mark = ''
    },
    showProductPopup() {
      let selectEntityAfter = {
        redirect: this.$router.currentRoute.fullPath,
        type: 'order',
        userId: this.orderUser.id
      }
      $store.commit('setSelectProductAfter', selectEntityAfter)
      this.$router.push({path: '/order/product/' + this.orderUser.id})
    },
    onConfirm(time) {
      console.log(time)
      this.orderInfo.orderDatetimeStr = `${time.getFullYear()}/${time.getMonth() + 1}/${time.getDate()} ${time.getHours()}:${time.getMinutes()}`;
      this.orderInfo.orderDatetime = time;
      this.showPicker = false;
    },
    changeCurrentTimeForPicker() {
      const currentTime = new Date();
      console.log(currentTime)
      this.orderInfo.orderDatetimeStr = `${currentTime.getFullYear()}/${currentTime.getMonth() + 1}/${currentTime.getDate()} ${currentTime.getHours()}:${currentTime.getMinutes()}`;
      this.orderInfo.orderDatetime = currentTime;
    },
    onAdd() {
      this.$router.push({path: '/user'})
    },
    showPopup() {
      let selectEntityAfter = {
        redirect: this.$router.currentRoute.fullPath,
        type: 'order',
      }
      $store.commit('setSelectEntityAfter', selectEntityAfter)
      this.$router.push({path: '/order/user'})
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({selectedOptions}) {
      this.selectAddressPopupShow = false;
      this.deliveryAddress = selectedOptions.map((option) => option.n).join(' ');
      this.orderInfo.address = selectedOptions.map((option) => option.n).join(' ');
      this.orderInfo.deliveryAddress = selectedOptions.map((option) => option.n).join(' ');
    },
    onAreaChange({selectedOptions}) {
      this.deliveryAddress = selectedOptions.map((option) => option.n).join(' ');
      console.log(this.deliveryAddress)
      this.orderInfo.address = selectedOptions.map((option) => option.n).join(' ');
      this.orderInfo.deliveryAddress = selectedOptions.map((option) => option.n).join(' ');
    },
    onPaymentStatusChange() {
      // 选择付清则自动设置全额为支付金额
      // 选择支付状态为结账时则设置支付金额为订单金额
      console.log(this.paymentStatusChecked)
      if (this.paymentStatusChecked === '2' || this.paymentStatusChecked === '1') {
        this.payPrice = this.amount / 100
      }
    },
    verifyPayment() {
      // 如果支付状态为付清 则支付金额必须等于订单金额
      // 如果支付状态为欠账 则支付金额必须小于订单金额
      // 选择付清则自动设置全额为支付金额
      // 选择支付状态为结账时则设置支付金额为订单金额
      console.log(this.payPrice)
      console.log(this.amount)
      if (Number(this.payPrice) > Number(this.amount) / 100) {
        Toast("支付金额不能高于订单金额")
        this.verifySuccess = false
        return
      }
      // 付清
      if (this.paymentStatusChecked === '1') {
        if (Number(this.amount) / 100 !== Number(this.payPrice)) {
          Toast("支付金额要等于订单金额")
          this.verifySuccess = false
          return
        }
      } else if (this.paymentStatusChecked === '0') {
        if (Number(this.payPrice) === Number(this.amount) / 100) {
          Toast("支付金额不能等于订单金额")
          this.verifySuccess = false
          return
        }
      }

      this.verifySuccess = true
    }
  },
};
</script>

<style lang="less">
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>
